<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-text>
        <filterComponents
          @doFilter="setFilter"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        {{ $t('savebox.saveBox') }}
        <v-spacer />
        <v-btn
          v-if="this.$route.name === 'saveboxReport'"
          outlined
          class="mx-2"
          color="primary"
          @click="exportExel()"
        >
          {{ $t('actions.print') }}
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="dataLoading"
        :headers="headers"
        :search="search"
        :items="list"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :options.sync="options"
        :server-items-length="total"
        :page-count="numberOfPages"
        @fetchAllItems="fetchAllItems"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            bottom
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-btn
                small
                fab
                outlined
                class="mx-2"
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="ConfirmEmpty(item)"
              >
                <v-icon>
                  fa-empty-set
                </v-icon>
              </v-btn>
            </template>
            {{ $t('actions.empty') }}
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-btn
                small
                fab
                outlined
                class="mx-2"
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="ConfirmMakeMoney(item)"
              >
                <v-icon>
                  fa-comments-dollar
                </v-icon>
              </v-btn>
            </template>
            {{ $t('actions.makePayment') }}
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-btn
                small
                fab
                outlined
                class="mx-2"
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="ConfirmMoneyTransfer(item)"
              >
                <v-icon>
                  fa-money-bill
                </v-icon>
              </v-btn>
            </template>
            {{ $t('actions.moneyTransfer') }}
          </v-tooltip>
        </template>
        <template
          v-slot:no-data
          loading
        >
          <p>{{ $t('actions.noData') }}</p>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog
      v-model="transferDialog"
      width="500"
    >
      <v-card>
        <v-toolbar
          color="blue"
          dark
        >
          Transfer Money
        </v-toolbar>
        <v-card-text>
          <p>{{ 'Current Branch : ' + branchName }}</p>
          <div class="pb-4">
            <label>
              Transfer To
            </label>
            <v-select
              v-model="transferForm.to_branch"
              :items="branches"
              label="Transfer To"
              item-text="name"
              item-value="id"
              outlined
              dense
            />
            <div class="pt-4">
              <label>
                Money (EGP)
              </label>
            </div>
            <v-text-field
              v-model="transferForm.payment_amount"
              label="Money (EGP)"
              dense
              outlined
              type="number"
            />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            color="red"
            @click="transferDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            outlined
            color="blue"
            @click="moneyTransfer()"
          >
            {{ $t('actions.moneyTransfer') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="paymentDialog"
      width="500"
    >
      <v-card>
        <v-toolbar
          color="blue"
          dark
        >
          Add New Payment
        </v-toolbar>
        <v-card-text>
          <div class="pb-4">
            <label>
              Money (EGP)
            </label>
            <v-text-field
              v-model="transferForm.payment_amount"
              label="Money (EGP)"
              dense
              outlined
              type="number"
            />
          </div>
          <div class="pb-4">
            <label>
              Reason
            </label>
            <v-textarea
              v-model="transferForm.reason"
              outlined
              name="Reason"
              label="Reason"
            />
          </div>
          <div>
            <v-select
              v-model="transferForm.payment_method"
              :items="payment_method"
              :label="$t('filter.paymentMethod')"
              item-text="name"
              item-value="id"
              outlined
              dense
            />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            color="red"
            @click="paymentDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            outlined
            color="blue"
            @click="makePayment()"
          >
            {{ $t('actions.makePayment') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="emptyDialog"
      width="500"
    >
      <v-card>
        <v-toolbar
          color="red"
          dark
        >
          empty Save Box
        </v-toolbar>
        <v-card-text>
          <p>{{ 'Are You Sure to empty ' + branchName }}</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            color="red"
            @click="emptyDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            outlined
            color="success"
            @click="empty()"
          >
            {{ $t('actions.empty') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  <script>
  import filterComponents from './filter.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  const branchesServices = ServiceFactory.get('branches')
  const saveboxService = ServiceFactory.get('savebox')
  export default {
    name: 'Companies',
    components: {
      filterComponents,
    },
    data: (vm) => ({
      search: '',
      dataLoading: false,
      page: 0,
      total: 0,
      numberOfPages: 0,
      options: {},
      list: [],
      branches: [],
      payment_method: [
        {
          id: 1,
          name: 'Cash',
        }, {
          id: 2,
          name: 'Visa',
        }, {
          id: 3,
          name: 'Bank Check',
        },
      ],
      filter: {
        branch_id: null,
        status: null,
        from_date: null,
        to_date: null,
        payment_method: null,
        client_id: null,
      },
      loading: false,
      transferDialog: false,
      paymentDialog: false,
      emptyDialog: false,
      itemDetails: {},
      transferForm: {
        from_branch: null,
        payment_amount: null,
        payment_method: 1,
        reason: null,
        to_branch: null,
      },
      branchName: '',
      editedIndex: -1,
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      disabled: false,
      headers: [
        {
          text: vm.$t('settings.id'),
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: vm.$t('savebox.branchMang'), sortable: false, value: 'employee.name' },
        { text: vm.$t('savebox.branch'), sortable: false, value: 'branches.name' },
        { text: vm.$t('savebox.paymentInSave'), sortable: false, value: 'total' },
        { text: vm.$t('savebox.date'), sortable: false, value: 'created' },
        { text: vm.$t('actions.actions'), value: 'actions', sortable: false },
      ],
    }),
    watch: {
      options: {
        handler () {
          this.fetchAllItems()
        },
      },
    },
    methods: {
      ConfirmMoneyTransfer (item) {
        console.log('item', item)
        this.fetchAllBranches()
        this.transferForm.from_branch = item.branch_id
        this.branchName = item.branches.name
        this.transferDialog = true
      },
      ConfirmMakeMoney (item) {
        this.transferForm.from_branch = item.branch_id
        this.branchName = item.branches.name
        this.paymentDialog = true
      },
      ConfirmEmpty (item) {
        this.transferForm.from_branch = item.branch_id
        this.branchName = item.branches.name
        this.emptyDialog = true
      },
      setFilter (value) {
        this.filter = value
        this.fetchAllItems()
      },
      async fetchAllItems () {
        this.dataLoading = true
        const { page, itemsPerPage } = this.options
        const pageNumber = page - 1
        const list = await saveboxService.getAllSaveBox(itemsPerPage, page, pageNumber, this.filter)
        this.list = list.data
        this.total = list.total
        this.dataLoading = false
      },
      async fetchAllBranches () {
        this.dataLoading = true
        const list = await branchesServices.getActiveList()
        this.branches = list.data
        this.dataLoading = false
      },
      async empty () {
        this.transferForm.branch_id = this.transferForm.from_branch
        const branchId = {
          branch_id: this.transferForm.from_branch,
        }
        const transfer = await saveboxService.emptySaveBox(branchId)
        if (transfer.status === 200) {
          this.fetchAllItems()
          this.successMessage = 'Successfly'
          this.successSnackbar = true
          this.emptyDialog = false
          setTimeout(() => {
            this.successSnackbar = false
          }, 3000)
        } else {
          this.errorMessage = transfer
          this.errorSnackbar = true
          this.emptyDialog = false
          setTimeout(() => {
            this.errorSnackbar = false
          }, 3000)
        }
      },
      async makePayment () {
        this.transferForm.branch_id = this.transferForm.from_branch
        const transfer = await saveboxService.addPayment(this.transferForm)
        if (transfer.status === 200) {
          this.successMessage = 'Successfly'
          this.successSnackbar = true
          this.paymentDialog = false
          setTimeout(() => {
            this.successSnackbar = false
          }, 3000)
        } else {
          this.errorMessage = transfer
          this.errorSnackbar = true
          this.paymentDialog = false
          setTimeout(() => {
            this.errorSnackbar = false
          }, 3000)
        }
      },
      async moneyTransfer () {
        console.log('this.transferForm', this.transferForm)
        const transfer = await saveboxService.makeTransfer(this.transferForm)
        if (transfer.status === 200) {
          this.successMessage = 'Successfly'
          this.successSnackbar = true
          this.transferDialog = false
          setTimeout(() => {
            this.successSnackbar = false
          }, 3000)
        } else {
          this.errorMessage('something Error')
          this.errorSnackbar = true
          this.transferDialog = false
          setTimeout(() => {
            this.errorSnackbar = false
          }, 3000)
        }
      },
    },
  }
  </script>
  <style>
  a{
    text-decoration: none;
  }
  </style>
